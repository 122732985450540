#employee_overlay_panel{
    background-color:rgb(1, 48, 75);
    .p-card-body{
        padding:5px 10px;
    }
    .p-card-content{
        padding:5px;
    }
    #errormessage{
        background:none;
        width:100%;
        color:black;
        border:0px solid grey;
    }
}
.changePassword-container{
    input{
        letter-spacing: 1.0em;
    }

}


#employeeform_maindiv{
    min-width:750px;
    max-width:900px;
    height:550px;
    overflow:auto;
    box-sizing: border-box;
    background-color:rgba(255,255,255,0.0);

    input{
        background-color:rgba(255,255,255,0.8);
        color:black
    }

    input:disabled, .p-dropdown.p-disabled, .p-multiselect.p-disabled{
        background-color:rgba(0,0,0,0.2);
        color: rgba(255, 255, 255, 0.87);
    }

    #listbox_roles{
        width:248px;
        padding:0px;
        li , ul{
            list-style-position: inside;
            border-width:0px;
            margin:0px;
            padding:0px;
        }
    } 


    .employeeform_panel_basicfields{
        label{
            width:160px;
        }
    }

    .employeeform_panel_userlists{
        width:380px;
    }

    .p-fieldset{
        margin:0px;
        padding:0px;
        background-color:rgba(0,0,0,0.2);
        background: none;
        border:1px solid silver;
        div{
            margin:auto;
            padding:2px;
        }
    }

    .p-fieldset-legend{
        background-color:rgba(0,0,0,0.1) !important;
        padding:10px;
        margin-left: 10px;
    }

    .p-field.p-grid{
        margin:4px;    
    }

    .p-button{
        height: 2rem;
        padding:0.25rem 0.7rem;
    }
    .p-button.p-highlight{
        background-color:#0b67c3;
    }

    .p-multiselect{
        width:100%;
    }
    .p-calendar{
        width:150px;
    }

    #fieldset_user, #fieldset_orgfunctions, #fieldset_qualfunctions{
        margin-bottom: 20px;
        margin-right: 10px;
        .p-field.p-grid{
            margin:0px;    
        }
        label{
            width:80px;
        }
    }

}

#employee-taglist {
    li, ul,.p-button{
        padding:0;
    }
}

#G26Date, #drivingLicenseDate{
    input, button {
        padding:0 0 0 10px;
    }
}

#secordsToArriveSite, #secordsToArriveHome{
    padding:0px;
}

.multiselect-employeeform li{
    padding:3px 10px !important;
}