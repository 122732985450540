.employee-time-entry-template-fieldset {

	.p-fieldset,
	.p-fieldset-content {
		height: 200px;
		background-color: none !important;
		margin: 0px;
		padding: 5px !important;
		background: none;


		.p-field-radiobutton {
			width: 100%;
			margin: 0 0 7px 0;

			.p-radiobutton {
				margin: 0px;
			}
		}
	}

	.p-fieldset-content {
		padding: 5px;
	}

	.p-fieldset-legend {
		padding: 5px 10px !important;
		background-color: rgb(5, 42, 65) !important;
	}
}

#dialog-timeentrytemplate {
	.p-dialog-header {
		padding: 10px;
	}
}

#calendarTimeEntryPanel {
	font-size: 12pt;
	width: 500px;
	background-color: rgb(5, 42, 65) !important;

	input {
		background-color: rgba(255, 255, 255, 0.4);
		color: black
	}

	#calendarTimeEntryPanel_timeEntryTitel {
		color: black
	}

	#calendarTimeEntryPanel_content {

		background-color: rgba(0, 0, 0, 0.2) !important;

	}

	.p-dialog-header-icons {
		padding-right: 5px;
	}

	.p-selectbutton .p-button.p-highlight {
		background-color: #8dd0ff !important;
		color: black;
	}

	.p-dialog-content {
		background-color: rgb(5, 42, 65) !important;
		color: rgba(255, 255, 255, 0.87);
		padding: 1rem;
	}

	.p-tabview .p-tabview-panels {
		padding: 0px;
	}

	#fieldset_available,
	#fieldset_notavailable {
		width: 200px !important;

		.p-fieldset,
		.p-fieldset-content {
			background-color: none !important;
			margin: 0px !important;
			padding: 0px !important;
			background: none;
			height: 160px;

			.p-field-radiobutton {
				width: 100%;
				margin: 0 0 5px 0;

				.p-radiobutton {
					margin: 2px 0 0 0;
				}
			}
		}

		.p-fieldset-legend {
			padding: 5px 10px;
			background-color: rgb(5, 42, 65) !important;
		}

	}

	.p-dialog-header {
		padding: 5px 0 0 0;
		background-color: rgb(5, 42, 65) !important;
		backgrounds: #4c596b;
	}

	.p-dialog-footer {
		padding: 0.5rem;
		background-color: rgb(5, 42, 65) !important;
	}

	.p-inputtext {
		padding: 0.25rem;
	}

	.p-dialog-title {
		width: 100%;
	}

	#calendarTimeEntryPanel_header {
		padding: 0.2rem;
	}

	.timeEntryTitel {
		width: 100%;
	}

	.box {
		background-color: var(--surface-e);
		text-align: center;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-radius: 4px;
		box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
	}

}