.userspage_dialog{
    width:600px;
    .p-dialog-content{
        padding:0.5rem !important;
    }
    .p-field-checkbox{
        margin:4px;
    }
    .p-field{
        margin:4px;
    }
    @media screen and (max-width: 600px) {
        width:100%;
    }
}

.userspage{

    #listbox_roles li{
        border-width:0 0 1px 0;
        border-color:grey;
        border-style:solid;
    }

    .userentry{
        color:rgb(221, 206, 206);
    }

    .p-datatable.userspage-datatable {

        .p-datatable-header, .p-datatable-scrollable-header{
            background-color:rgba(0,0,0,0.4)  !important;  
        }
        .p-datatable-tbody, .p-datatable-thead {
            > tr > td .p-column-title {
                background: none !important; 
                display: none;
            }

            tr {
                background: none !important; 
                border-width:1px 0 0 0 ;
                border-style:solid;
                border-color:silver;
                td{
                    padding:4px;
                    background: none !important;
                    border-width:0px;
                }
                th {   
                    background: none !important; 
                    padding:4px;
                }
            }
            .column-select{
                width:50px !important;
            }
            .column-email, .column-username{
                width:200px;
            }
        }
        .no-employee{
            background-color:rgba(255,0,0,0.2)  !important;
        }
    }

    @media screen and (max-width: 500px) {
        .p-datatable.userspage-datatable {
            .p-datatable-thead .column-email .p-column-title{
                display: none;
            }
            > tr > th > .column-username {
                width:100%;
            }
            .column-email, .column-username {
                text-align: left;
                display: block;
                float: left;
                clear: left;
                border: 0 none;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .p-datatable.userspage-datatable{
             .column-permissions {
                display: none;
             }
             .column-roles {
                display: none;
            }
        }
    }
    @media screen and (max-width: 800px) {
        .p-datatable.userspage-datatable{
             .column-roles {
                display: none;
            }
        }
    }
}
