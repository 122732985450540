html {
	height: 100%;
}

body {
	background-image: url('./bg_2600_full.jpg');
	margin: 0;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root {
	height: 100%;
}

.hamburger-menu {
	margin-right: .5rem !important;
	padding: 2px 2px 0 6px;
}

#navigation-headline {
	height: 50px;
	background-color: rgba(0, 0, 0, 0.3);
}


.p-sidebar {
	background: rgba(2, 33, 55, .80);
	overflow: auto;
}

.p-menu {
	background: rgb(2, 33, 55);
}

.p-menu .p-submenu-header {
	background: none;
}

.p-panelmenu .p-panelmenu-header>a {
	background: rgb(2, 33, 55);
}


.p-button {
	color: #fff;
	background: rgba(2, 33, 55, .80);
	border: 1px solid silver;
	padding: .5rem .75rem;
	font-size: 1rem;
	transition: background-color .15s, border-color .15s, box-shadow .15s;
	border-radius: 4px;
}

.p-datatable {
	background: none !important;
	color: rgba(255, 255, 255, 0.87);

	.p-datatable-scrollable-header {
		background-color: rgba(0, 0, 0, 0.4);
		border: 1px solid silver !important;
	}

	.p-datatable-header {
		background-color: rgba(0, 0, 0, 0.4);
		border: 1px solid silver !important;
	}

	.p-datatable-tbody {

		tr,
		td {
			background: none !important;
			border-bottom-width: 1px;
			border-color: silver !important
		}
	}

	.p-datatable-thead>tr>th {
		background-color: rgba(0, 0, 0, 0.2);
		border: none;

		.weekend {
			background-color: rgba(0, 0, 0, 0.6) !important;
		}
	}
}

.p-card {
	background: none;
	box-shadow: none;
}

.p-fieldset {
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px color silver;
}

.p-fieldset-legend {
	background-color: rgb(1, 48, 75) !important;

}

.p-tabview .p-tabview-panels {
	background: none;
}

#nav-page-tabview {
	background: none !important;
	border: 1px color silver;

}

.p-tabview-selected {
	background-color: rgba(0, 0, 0, 0.4) !important;
}

.p-tabview .p-tabview-nav li li.p-highlight .p-tabview-nav-link {
	background-color: rgba(0, 0, 0, 0.4) !important;
	border-color: #3f4b5b #3f4b5b #2a323d #3f4b5b;
	color: rgba(255, 255, 255, 0.6);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background-color: rgba(0, 0, 0, 0.4) !important;
}

#fieldset_changePassword .p-fluid {
	width: 100%;

}

#fieldset_changePassword .p-field {
	margin-bottom: 0.5rem;
}

#fieldset_changePassword .p-password input {
	width: 100%;
	letter-spacing: 0.1em;
}

#fieldset_changePassword .p-col-fixed {
	width: 320px;
}

#fieldset_changePassword .p-fieldset-content {
	padding: 0px;
}

#userInactiveIntervall input {
	background-color: rgb(1, 48, 75) !important;
	background: none !important;
	border: 1px solid darkgray;
	height: 30px;
	width: 30px;
}

#userInactiveIntervall .p-button.p-button-icon-only {
	width: 1.5rem;
	padding: 0px;
}

#userInactiveIntervall .p-inputtext-sm {
	padding: 3px 5px;
}

#userInactiveIntervall .p-inputtext {
	padding: 2px 5px;
}