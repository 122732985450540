#manage-simple-list-page {
    .p-button {
        color: #fff;
        background: rgba(2,33,55,.80);
        border: 1px solid silver;
        padding: .5rem .75rem;
        font-size: 1rem;
        transition: background-color .15s,border-color .15s,box-shadow .15s;
        border-radius: 4px;
        }

}

#manage-simple-list-page .button:disabled {
    background-color:rgb(255, 255, 255,0.2);
    background-color:grey;
}

#manage-simple-list-page-panel .p-button.p-highlight{
    background-color:#0b67c3;
}