#employeelist_datatable {
	background: none !important;


	.date-overdue {
		color: darkred;
		background-color: rgba(255, 255, 255, 0.4);
		padding: 2px;
		text-align: center;
		font-weight: bold;
	}

	.date-valid {
		color: darkgreen;
		background-color: rgba(255, 255, 255, 0.4);
		padding: 2px;
		text-align: center;
	}

	.p-filter-column {
		input {
			width: 180px;
		}
	}

	tr {
		background: none !important;
		color: rgba(255, 255, 255, 1) !important;

	}

	th {
		background: none !important;

	}

	.p-datatable-thead>tr>th {
		border-color: rgba(0, 0, 0, 0.4) !important;
	}

	.p-inputtext {
		background-color: rgba(0, 0, 0, 0.4) !important;
	}

	.p-datatable-scrollable-footer {
		background: none !important;
	}

	.p-radiobutton .p-radiobutton-box {
		background: none !important;
		border-color: #8dd0ff;
	}

	.p-radiobutton .p-radiobutton-box.p-highlight {
		border-color: #8dd0ff !important;
		background: #8dd0ff !important;
	}

	tr.p-highlight {
		background: #8dd0ff !important;
		color: #151515 !important;
	}

	.p-datatable {
		background: none !important;
	}

	.p-datatable-header {
		background-color: rgba(0, 0, 0, 0) !important;
		padding: 0 10px;
		border-width: 0px !important;
	}

	.p-datatable-scrollable-header {
		background-color: rgba(0, 0, 0, 0) !important;
		border: 0px solid silver !important;
	}

}